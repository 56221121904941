<script>
    import Image from '../components/recursos/image.svelte';
  </script>


  <style>
  * {
    box-sizing: border-box;
  }


  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row{
    justify-items: center;
  }

  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000;
  padding: 0 1rem;
  }

  .col-5 {width: 41.66%;}
  .col-7 {width: 58.33%;}

.icones{
    width:10vw;height:10vw;
    margin:-20px;
    position: absolute;
    bottom:0;
}




.bloco{
    left:0;min-width: 300px; margin-left:16vw;margin-right:16vw;justify-content: center;background-color: #01e7e5;
  }
  #como{
    width: 70vw;
    margin: 0 15vw;
  }
  #card{
margin:0;width: 20vw;
  }
  @media (min-width: 500px){
    .shadow-sm{
      min-height:470px;
    }}
  @media (max-width: 500px) {
    .shadow-sm{
      width: 100%;
    }
    .icones{
      width:auto;height:100px;
      margin:-20px;
      position: absolute;
      bottom:0;
    }
    #card{
      min-width: 100vw;
  }
  .bloco{
    left:0;min-width: 100vw; margin-left:0;margin-right:0;justify-content: center;background-color: #01e7e5;
  }
  }
  @media (min-width: 1700px) {
    #card{
      min-width: 350px;
  }
  }
  @media (min-width: 1950px) {
    #card{
      min-width: 400px;
  }
  }
  @media (max-width: 1500px) {
    #como{
    width: 100vw;
    margin: 0 ;
  }
  }

  </style>



  <div id='pag1' class='d-none d-md-block' style=" margin-top: 100px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1</h5></div>
                <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Análise de mercado</h2>
            </div>
            <h3>Gere insights sobre seu mercado
                a partir de dados diponíveis na web</h3>
            <p>Informações precisas e relevantes se tornaram indispensáveis para a saúde
                e crescimento do seu negócio. Use a grande quantidades de dados disponíveis
                na internet pra tomar decisões baseadas em informação e não em intuição.</p>
        </div></div>
          <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 01 casos.png"/>
    </div></div></div></div></div>



    <div id='pag1' class='d-block d-md-none' style=" margin-top: 100px;">
      <div style=" align-items: center;">
        <div class="row" style="margin: 0;padding:0;">
          <div class="col" style='min-width: 300px;'>
              <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex;min-height:50px;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
              <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1</h5></div>
                  <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Análise de mercado</h2>
              </div>
              <h3>Gere insights sobre seu mercado
                  a partir de dados diponíveis na web</h3>
              <p>Informações precisas e relevantes se tornaram indispensáveis para a saúde
                  e crescimento do seu negócio. Use a grande quantidades de dados disponíveis
                  na internet pra tomar decisões baseadas em informação e não em intuição.</p>
          </div></div>
            <div class="col" style=''>
                <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
          <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 01 casos.png"/>
      </div></div></div></div></div>











      <div class="">

        <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
          <div id='card' class="col" style='margin:1vw;'>
            <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
              <div class="card-body" style='min-width: 300px;'>

                <div style='display:flex; min-height:50px;;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1.1</h5></div>
                  <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Dados do setor imobiliário</h5>
                </div>
                <p style='padding-bottom: 7vw;padding-top: 10px;'>Colete dados dos maiores sites
                    imobiliários do país e veja quais
                  aréas estão crescendo, identifique
                  o melhor lugar para o seu proximo
                  empreendimento, gere insights
                  e oportunidades para o seu negócio.</p>
                <img alt='' src='assets/Casos de uso/icones/icone casa.png' style="" class='icones'/>
                <button onclick="window.location.href='#/casos/imobiliario'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>
              </div>
            </div>
          </div>
          <div id='card' class="col" style='margin:1vw;'>
            <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
              <div class="card-body" style='min-width: 300px;'>
                <div style='display:flex; min-height:50px;;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1.2</h5></div>
                  <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Dados do setor automotivo</h5>
                </div>
                <p style='padding-bottom: 7vw;padding-top: 10px;'>Monitore preços de veículos,
                  popularidade de marcas e
                  encontre muitas oportunidades.</p>
                <img alt='' src='assets/Casos de uso/icones/icone carro.png' style="" class='icones'/>
                <button onclick="window.location.href='#/casos/automotivo'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>
              </div>
            </div>
          </div>

          <div id='card' class="col" style='margin:1vw;'>
            <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
              <div class="card-body" style='min-width: 300px;'>
                <div style='display:flex; min-height:50px;;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1.3</h5></div>
                  <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Dados de E-commerce e marketplaces</h5>
                </div>
                <p style='padding-bottom: 7vw;padding-top: 10px;'>Consulte diversas informações
                    nos principais sites de comércio
                    eletrônico do Brasil. Analise preços,
                    ofertas e vendedores ou qualquer
                    informação sobre produtos em
                    qualquer marketplace ou loja virtual.</p>
                <img alt='' src='assets/Casos de uso/icones/icone e commerce.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
              </div>
            </div>
          </div>

          <div id='card' class="col" style='margin:1vw;'>
            <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
              <div class="card-body" style='min-width: 300px;'>
                <div style='display:flex; min-height:50px;;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1.4</h5></div>
                  <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Vagas de empregos</h5>
                </div>
                <p style='padding-bottom: 7vw;padding-top: 10px;'>Entenda como o mercado de trabalho está s
                    e movimentando, monitore oportunidades de empregos.</p>
                <img alt='' src='assets/Casos de uso/icones/icone vagas.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
              </div>
            </div>
          </div>
          <div id='card' class="col" style='margin:1vw;'>
            <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
              <div class="card-body" style='min-width: 300px;'>
                <div style='display:flex; min-height:50px;;'>
                  <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1.5</h5></div>
                  <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Mídias sociais</h5>
                </div>
                <p style='padding-bottom: 7vw;padding-top: 10px;'>Monitore discuções sobre sua empresa, competidores.
                    Descubra tendènciais, colete dados para uso academico,
                    treinamento de IA etc.</p>
                <img alt='' src='assets/Casos de uso/icones/icone midias sociais.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" style="margin-top: 5vw;">



        <div class="bloco" style=''>
          <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
            <div class="card-body" style='min-width: 300px;'>
              <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
          a datallog pode ajudar seu negócio?</h2>
            </div>
              <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
          </div>    </div>    </div>
      </div>



























<div class="album py-5 bg-light d-none d-md-block" style="margin-top: 50px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Automação web</h2>
          </div>
          <h3>Automatize os fluxos de trabalho
            repetitivo executados em um
            navegador WEB da sua empresa</h3>
          <p>
            Todo trabalho repetitivo em um navegador Web pode ser automatizado e
executado em escala. Ganhe agilidade e reduza o custo de backoffice
automatizando as tarefas repetitivas que sua força de trabalho executa
manualmente em um navegador web. Deixe os robôs da datallog fazerem
o trabalho maçante da sua empresa.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 02 casos.png"/>
  </div></div></div></div></div>






  <div class="album py-5 bg-light d-block d-md-none" style="margin-top: 50px;">
    <div style=" align-items: center;">
      <div class="row" style="margin: 0;padding:0 40px 0 0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Automação web</h2>
          </div>
          <h3>Automatize os fluxos de trabalho
            repetitivo executados em um
            navegador WEB da sua empresa</h3>
          <p>
            Todo trabalho repetitivo em um navegador Web pode ser automatizado e
executado em escala. Ganhe agilidade e reduza o custo de backoffice
automatizando as tarefas repetitivas que sua força de trabalho executa
manualmente em um navegador web. Deixe os robôs da datallog fazerem
o trabalho maçante da sua empresa.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 02 casos.png"/>
  </div></div></div></div></div>













    <div class="">

      <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2.1</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Baixar e ler
                  documentos online</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Processos como baixar, ler e
                armazenar documentos, quando
                executados repetitivamente além
                de ser maçante e consumir muito
                tempo, estão sujeitos a erros
                humanos.</p>
              <img alt='' src='assets/Casos de uso/icones/icone docs online.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2.2</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Consulta de
                  certidões públicas</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>
                O dia a dia da sua empresa é
                  marcado pela consulta de diversas
                  certidões públicas, facilitamos o
                  acesso sistêmico aos resultados,
                  via API. permitindo a automação
                  das consultas em escala.
              </p>
              <img alt='' src='assets/Casos de uso/icones/icone editais.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>

        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2.3</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Automação contábil</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Processos como pesquisar, copiar,
                faturar, pagamentos, logins
                bancários e clicar em vários
                sistemas podem ser automatizados
                para a redução de tempo e erros.</p>
              <img alt='' src='assets/Casos de uso/icones/icone automacao contabil.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>

        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2.4</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Preencher informações e
                  formulários online</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Deixe os robôs datallog fazerem o trabalho repetitivo de
                preencher formulários online. Preencha centenas
                de formulários em apenas um click.</p>
              <img alt='' src='assets/Casos de uso/icones/icone formulario online.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">2.5</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Marketing em um clique</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Adicionar conteúdo em vários canais de marketing e
                acompanhar seu progresso pode se tornar trabalhoso
                e caro. Os robôs datallog podem realizar todas
                essas tarefas para você.</p>
              <img alt='' src='assets/Casos de uso/icones/icone fmkt em um clique.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="margin-top: 5vw;">


      <div class="bloco" style=''>
        <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
          <div class="card-body" style='min-width: 300px;'>
            <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
        a datallog pode ajudar seu negócio?</h2>
          </div>
          <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
    </div>  </div>    </div>
    </div>





































<div class="album py-5 bg-light d-none d-md-block" style="margin-top: 50px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de informação</h2>
          </div>
          <h3>
            Monitore preços, produtos,
competidores e defina uma
estratégia de monitoramento.
          </h3>
          <p>
            Com a raspagem de dados é possível monitorar milhões de sites em tempo real.
Use essas informações e gere notificações importantes para o seu negócio.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 03 casos.png"/>
  </div></div></div></div></div>





  <div class="album py-5 bg-light d-block d-md-none" style="margin-top: 50px;">
    <div style=" align-items: center;">
      <div class="row" style="margin: 0;padding:0 40px 0 0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de informação</h2>
          </div>
          <h3>
            Monitore preços, produtos,
competidores e defina uma
estratégia de monitoramento.
          </h3>
          <p>
            Com a raspagem de dados é possível monitorar milhões de sites em tempo real.
Use essas informações e gere notificações importantes para o seu negócio.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 03 casos.png"/>
  </div></div></div></div></div>





    <div class="">

      <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3.1</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de preços</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Rastreie os produtos nas lojas online de seus concorrentes,
                e ajustes automaticamente os preços pra otimizar as vendas.</p>
              <img alt='' src='assets/Casos de uso/icones/icone monitora precos.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3.2</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de citações</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>
                Não perca os prazos em nenhuma citação de disputa de
                licitações, ações trabalhistas e cívies.
              </p>
              <img alt='' src='assets/Casos de uso/icones/icone monitora citacoes.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="margin-top: 5vw;">


      <div class="bloco" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>
        <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
    </div>    </div>    </div>
</div>






























<div class="album py-5 bg-light d-none d-md-block" style="margin-top: 50px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">4</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Processos de documentos</h2>
          </div>
          <h3>
            Automatize a leitura e análise de
            documentos do seu negócio.
            Automatize a consulta e análise de
            documentos das tarefas do dia a dia.
          </h3>
          <p>
            O dia a dia do trabalho é marcado por leitura e análise de documentos,
quando esse processo se torna demorado e repetitivo é hora de repensar
a forma de trabalhar. Automatizamos o processo de consulta, leitura, processamento
e armazenamento das informações contidas em documentos.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 04 casos.png"/>
  </div></div></div></div></div>






  <div class="album py-5 bg-light d-block d-md-none" style="margin-top: 50px;">
    <div style=" align-items: center;">
      <div class="row" style="margin: 0;padding:0 40px 0 0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">4</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Processos de documentos</h2>
          </div>
          <h3>
            Automatize a leitura e análise de
            documentos do seu negócio.
            Automatize a consulta e análise de
            documentos das tarefas do dia a dia.
          </h3>
          <p>
            O dia a dia do trabalho é marcado por leitura e análise de documentos,
quando esse processo se torna demorado e repetitivo é hora de repensar
a forma de trabalhar. Automatizamos o processo de consulta, leitura, processamento
e armazenamento das informações contidas em documentos.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 04 casos.png"/>
  </div></div></div></div></div>












    <div class="">

      <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">4.1</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Baixar, ler e salvar PDFs</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Quando o fluxo de trabalho de algum processo da sua empresa envolve a consulta e leitura de documentos,
                a medida que a frequência desse processo aumenta o tempo e recursos gastos podem se tornar uma dor de cabeça.
                Automatizar parte desse processo com a leitura dos documentos pode reduzir em até 90% o tempo gasto.</p>
              <img alt='' src='assets/Casos de uso/icones/icone baixar pdfs.png' style="" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="margin-top: 5vw;">


      <div class="bloco" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>
        <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
    </div>    </div>    </div>
</div>




























<div class="album py-5 bg-light d-none d-md-block" style="margin-top: 50px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">5</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Avaliação de fornecedores</h2>
          </div>
          <h3>
            Tenha a avaliação de fornecedores
            em segundos na sua mão
          </h3>
          <p>
            A avaliação de fornecedores antes da contratação do serviço por parte das
empresas é uma prática comum e muitas vezes obrigatória, para a análise
e redução de riscos.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 05 casos.png"/>
  </div></div></div></div></div>



  <div class="album py-5 bg-light d-block d-md-none" style="margin-top: 50px;">
    <div style=" align-items: center;">
      <div class="row" style="margin: 0;padding:0 40px 0 0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">5</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Avaliação de fornecedores</h2>
          </div>
          <h3>
            Tenha a avaliação de fornecedores
            em segundos na sua mão
          </h3>
          <p>
            A avaliação de fornecedores antes da contratação do serviço por parte das
empresas é uma prática comum e muitas vezes obrigatória, para a análise
e redução de riscos.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 05 casos.png"/>
  </div></div></div></div></div>









  <div class="">

      <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">5.1</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Logisticas e fretes</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Reduza o riscos com motoristas e caminhões terceirizados,
                automatizando a verificação da situação dos mesmos nos
                Detrans.</p>
              <img alt='' src='assets/Casos de uso/icones/icone fretes.png' style="margin:0px 0 -10px" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">5.2</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Certidões negativas</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>
                Em média, 2 minutos é o tempo que uma pessoa leva para
consultar e baixar uma certidão em portais públicos.
Automatize esse processo e tenha consultas a antecedentes
criminais, débitos com a união entre outros em segundos.
              </p>
              <img alt='' src='assets/Casos de uso/icones/icone certidoes negativas.png' style="margin:0px 0 -10px" class='icones'/>
    <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="margin-top: 5vw;">


      <div class="bloco" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>
        <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
    </div>    </div>    </div>
</div>




























<div class="album py-5 bg-light d-none d-md-block" style="margin-top: 50px;">
    <div style=" align-items: center;width: 70vw;margin:0 15vw">
      <div class="row" style="margin: 0;padding:0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">6</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Prospecção de oportunidades</h2>
          </div>
          <h3>
            Monitore a WEB 24/7 em busca de
leads e oportunidades.
          </h3>
          <p>
            A internet é uma fonte de oportunidades, porém analisar diariamente esse volume
de dados se torna humanamente impossível. Com o uso da tecnologia datallog,
coletamos e compilamos esses dados pra sua empresa.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 06 casos.png"/>
  </div></div></div></div></div>




  <div class="album py-5 bg-light d-block d-md-none" style="margin-top: 50px;">
    <div style=" align-items: center;">
      <div class="row" style="margin: 0;padding:0 40px 0 0;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">6</h5></div>
              <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Prospecção de oportunidades</h2>
          </div>
          <h3>
            Monitore a WEB 24/7 em busca de
leads e oportunidades.
          </h3>
          <p>
            A internet é uma fonte de oportunidades, porém analisar diariamente esse volume
de dados se torna humanamente impossível. Com o uso da tecnologia datallog,
coletamos e compilamos esses dados pra sua empresa.
          </p>
      </div></div>
        <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 40vw;margin-top: -10vw;margin-bottom: -5vw;max-width:800px;min-width: 300px;'class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra 06 casos.png"/>
  </div></div></div></div></div>








    <div class="">

      <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>

              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">6.1</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Editais</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>Um dos grandes desafios das empresas licitantes é a
                classificação dos editais de interesse. A datallog coleta
                e classifica milhares de editais diariamente. Veja caso de uso.</p>
              <img alt='' src='assets/Casos de uso/icones/icone editais.png' style="margin:0px 0 -10px" class='icones'/>
              <button onclick="window.location.href='#/casos/licitacoes'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>
            </div>
          </div>
        </div>
        <div id='card' class="col" style='margin:1vw;'>
          <div class="card shadow-sm" style='height: auto;min-width: 300px;background-color: #d8d8d8;'>
            <div class="card-body" style='min-width: 300px;'>
              <div style='display:flex; min-height:50px;;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">6.2</h5></div>
                <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Setor automotivo</h5>
              </div>
              <p style='padding-bottom: 7vw;padding-top: 10px;'>
                Empresas de venda e revenda de carros, constantemente
buscam por oportunidades de negócios em marketplaces e
sites, automatizamos a busca de oportunidades e envio
de mensagens. Veja caso de uso.
              </p>
              <img alt='' src='assets/Casos de uso/icones/icone setor automotivo.png' style="margin:0px 0 -10px" class='icones'/>
              <button onclick="window.location.href='#/casos/automotivo'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="margin-top: 5vw;">


      <div class="bloco" style=''>
    <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
      <div class="card-body" style='min-width: 300px;'>
        <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
    a datallog pode ajudar seu negócio?</h2>
      </div>
        <div style="margin:0 50px 30px 0 ;text-align:right;">
      <a href="#/contato" style="">
        <img alt='' style="height: 30px;width: 250px;" src='assets/images/icons/botao2.png'>
      </a>
    </div>    </div>    </div>
</div>





































