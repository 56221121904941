<script>
  import { link, location } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import FaArrowLeft from 'svelte-icons/fa/FaArrowLeft.svelte';
  import FaArrowRight from 'svelte-icons/fa/FaArrowRight.svelte';
  import FaBars from 'svelte-icons/fa/FaBars.svelte';
  import FaRegFilePdf from 'svelte-icons/fa/FaRegFilePdf.svelte';
  import Container from './layout/container.svelte';

  import Sidebar from './sidebar.svelte';
  import navegacaoStore from '../store';

  let dados;

  navegacaoStore.subscribe((dadosStore) => {
    dados = dadosStore;
  });

  const openSidebar = () =>
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, sidebarAberta: true };
    });

  const updatePaginaAtual = () => {
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, paginaAtual: Number($location.split('/')[1]) };
    });
  };
</script>






<!-- Footer -->
<footer class="footer">
  <!-- Section: Social media -->


  <div class="container" >
    <div class="row"  style='width:80vw; margin:0; padding:0;'>
      <div class="col" style='width: 40%;'>
          <div class="card-body" style='min-width: 300px;text-align: left;'>

            <h6 class="text-uppercase fw-bold mb-4" style='text-align: center;'>
              Nossos Termos
            </h6>
            <ul>
              <li>
                Integração com seus sistemas
              </li>
              <li>
                Suporte completo 24/7
              </li>
              <li>
                Qualquer tipo e volume de dados
              </li>
              <li>
                Não coletamos dados para spam
              </li>
              <li>
                Não invadimos nem hackeamos nada
              </li>
              <li>
                Não buscamos dados pessoais
              </li>
            </ul>
            
          </div>
      </div>
      <div class="col" style=''>
          <div class="card-body" style='min-width: 300px;text-align: left;'>
            <h6 class="text-uppercase fw-bold mb-4" style='text-align: center;'>
              Segurança
            </h6>
            <p>
              Nos adequamos às necessidades de compliance e segurança e trabalhamos com criptografia de ponta a ponta em todos os projetos.
            </p>
          </div>
      </div>

      <div class="col" style=''>
          <div class="card-body" style='min-width: 300px;text-align: left;'>
            <h6 class="text-uppercase fw-bold mb-4" style='text-align: center;'>Contato</h6>
            <p><i class="fas fa-home"></i>  Rua Patrício Farias, 101. Itacorubi, Florianópolis-SC, 88034-132</p>
            <p>
              <i class="fas fa-envelope"></i>  <a href="mailto:contato@datallog.com">contato@datallog.com</a>
            </p>
            <p><i class="fas fa-phone"></i>  +55 (48) 98832.2403</p>
            <section class="d-flex justify-content-center justify-content-lg-between p-4" style="margin: 0;padding:0;max-width: 500px;border:none;">
               
                <a style="margin: 1vw;" href="https://www.instagram.com/datallog/" class="me-4 link-secondary">
                  <i class="fab fa-instagram fa-2x"></i>
                </a>
                <a style="margin: 1vw;" href="https://www.linkedin.com/company/datallog/" class="me-4 link-secondary">
                  <i class="fab fa-linkedin fa-2x"></i>
                </a>
                <a style="margin: 1vw;" href="https://api.whatsapp.com/send?phone=5548988322403&text=Oi!" class="me-4 link-secondary">
                  <i class="fab fa-whatsapp fa-2x"></i>
                </a>
                <a style="margin: 1vw;" href="https://www.youtube.com/watch?v=QsW6xoejB9o" class="me-4 link-secondary">
                  <i class="fab fa-youtube fa-2x"></i>
                </a>
            </section>
          </div>
      </div>
    </div>
  </div>
  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.025);color:gray;margin-top:-50px">
    © 2022 Todos os direitos reservados a Datallog
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->


<style>
  footer {
    background-color: #0a090a;
    padding:0;
    bottom: 0;
    margin-top:100px;
    z-index: 800;
  } 

.container{
  
  width:100vw;
  margin: 0 10vw;
  padding:0 ;
}
ul{
  text-align: left;
}
.card-body p{
  margin: 10px;
  color:white;
}
.row{
  color:white;
  
}
h6{
  color:white;
}
li{  color:white;}
.col{
  margin:0; padding:0;
}
</style>
