<script>
  import { link, location } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import FaArrowLeft from 'svelte-icons/fa/FaArrowLeft.svelte';
  import FaArrowRight from 'svelte-icons/fa/FaArrowRight.svelte';
  import FaBars from 'svelte-icons/fa/FaBars.svelte';
  import FaRegFilePdf from 'svelte-icons/fa/FaRegFilePdf.svelte';
  import Container from './layout/container.svelte';

  import Sidebar from './sidebar.svelte';
  import navegacaoStore from '../store';

  let dados;

  const openSidebar = () =>
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, sidebarAberta: true };
    });

  const updatePaginaAtual = () => {
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, paginaAtual: Number($location.split('/')[1]) };
    });
    toggleSidebar();
  };

  const toggleSidebar = () => {
    var display1 = document.getElementById('burguer').style.display;
    if (display1 == 'none') {
      document.getElementById('burguer').style.display = 'block';
    } else {
      document.getElementById('burguer').style.display = 'none';
    }
  };
</script>

<Container>
  <div class="container-header">
    <div class="header">
      <div class="subnav-logo">
        <a href="#/"
          ><button class="subnavbtn">
            <img alt="" src="assets/logo datallog/Logo Datallog 04-02.png" class="logo" /></button
          ></a
        >
      </div>
      <div style="justify-content: end;height:40px;display:flex;">
        <div class="subnav" style="min-width: 40px;">
          <button class="subnavbtn">Soluções </button>
          <div class="subnav-content">
            <a href="#/solucoes/sdk">SDK/Plataforma</a>
            <a href="#/solucoes/doc">NFSeData</a>
            <a href="#/solucoes/imob">ImobData</a>
            <a href="#/solucoes/licita">LicitaData</a>
            <a href="#/solucoes/cnd">CNData</a>
            <a href="#/solucoes/prospect">ProspectAuto</a>
          </div>
        </div>

        <div class="subnav" style="min-width: 40px;">
        <button class="subnavbtn">Casos de uso </button>
          <div class="subnav-content">
            <a href="#/casos/analise">Análise de mercado</a>
            <a href="#/casos/automacao">Automação web</a>
            <a href="#/casos/monitoramento">Monitoramento da informação</a>
            <a href="#/casos/processos">Leitura de documentos</a>
            <a href="#/casos/avaliacao">Avaliação de fornecedores</a>
            <a href="#/casos/prospeccao">Prospecção de oportunidades</a>
          </div>
        </div>
        <!--
          <div class="subnav"  style="min-width: 40px;">
              <a href="#/blog"><button class="subnavbtn">Blog </button></a>
          </div>
        -->
        <div class="subnav" style="min-width: 40px;">
          <a href="#/faq"><button class="subnavbtn">FAQ</button></a>
        </div>



        <div class="subnav d-none d-md-block" style="min-width: 40px;">
          <a target="_blank" href="https://step-hub.datallog.com/"
            ><button class="subnavbtn"
              >Login <img alt="" src="assets/main page/icones/login.png" class="login" /></button
            ></a
          >
        </div>

        <div class="subnav" style="min-width: 40px;">
          <a href="#/contato"
            ><button class="subnavbtn d-none d-md-block" id="especialista">Falar com um especialista </button>
            ></a
          >
        </div>
      </div>
    </div>
  </div>
</Container>

<div class="container">
  <input type="checkbox" id="checkbox-menu" />

  <label for="checkbox-menu" on:click={toggleSidebar}>
    <span />
    <span />
    <span />
  </label>
  <div id="burguer">
    <div class="mod-subnav" style="min-width: 40px;">
      <a href="#/solucoes"><button class="mod-subnavbtn">Soluções </button></a>
    </div>
    <div class="mod-subnav" style="min-width: 40px;">
      <a href="#/casos"><button class="mod-subnavbtn">Tecnologia para</button></a>
    </div>
    
            <div class="mod-subnav"  style="min-width: 40px;">
                <a href="#/casos/nfse"><button class="mod-subnavbtn">Casos de uso</button></a>
            </div>

    <div class="mod-subnav" style="min-width: 40px;">
      <a href="#/faq"><button class="mod-subnavbtn">FAQ</button></a>
    </div>

    <div class="mod-subnav" style="min-width: 40px;">
      <a href="#/contato"
        ><button class="mod-subnavbtn" >Contato </button></a
      >
    </div>
    <div on:click={toggleSidebar} class="fecha" style="min-width: 40px;">
      <button class="fecha1" style="background: none; border:none;color:white;" id="">Fechar</button
      >
    </div>
  </div>
</div>

<style>
  /* header {
    background: #662482;
    border-radius: 40px 40px 0 0;
    margin-top: 100px;
} */
  .subnavbtn {
    background: none;
    border: 0em;
  }
  .subnavbtn:hover {
    color: black;
  }
  .container-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
  }
  .header {
    /*border-bottom: 1px solid black;*/
    height: 40px;
    padding: 0 15vw;
    z-index: 90;
    top: 0;
    background-color: #01e7e5;
    width: 100vw;
    position: absolute;

    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }
  .logo {
    padding: 0;
    height: 70px;
    margin: -15px 0 -10px 0;
  }

  .login {
    height: 20px;
    position: relative;
  }
  #especialista {
    width: auto;
    height: 40px;
    color: #fff;
    background-color: #000;
    text-align: center;
    justify-content: center;
    margin: 0;
    padding: 0 10px;
  }
  #especialista:hover {
    color: #01e7e5;
  }

  /* The subnavigation menu */
  .subnav {
    float: left;
    overflow: hidden;
    margin: 0 0.7vw;
  }

  /* Subnav button */
  .subnav .subnavbtn {
    font-size: 12pt;
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    font-family: Arial;
    margin: 0;
    padding: 0 10px;
  }

  /* Add a red background color to navigation links on hover */
  .subnav:hover .subnavbtn {
    color: black;
    border-bottom: 1px solid #fff;
    background-color: darkcyan;
  }

  /* The subnavigation menu */
  .subnav-logo {
    float: left;
    overflow: hidden;
    height: 40px;
    max-height: 40px;
  }

  /* Subnav-logo button */
  .subnav-logo .subnavbtn {
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  /* Style the subnav content - positioned absolute */
  .subnav-content {
    display: none;
    position: absolute;
    background-color: #01e7e5;
    width: 140px;
    align-items: center;
    text-align: center;
    z-index: 1;
    padding: 0;
  }

  /* Style the subnav links */
  .subnav-content a {
    float: left;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }

  /* Add a grey background color on hover */
  .subnav-content a:hover {
    background-color: aquamarine;
    color: black;
    border: 1px solid #000;
  }

  /* When you move the mouse over the subnav container, open the subnav content */
  .subnav:hover .subnav-content {
    display: block;
  }
  a:hover {
    text-decoration: none;
  }

  .subnav button {
    border-radius: 0;
    padding: 0;
    height: 40px;
  }

  .container {
    display: none;
  }

  @media (max-width: 1300px) {
    .header {
      border-bottom: 1px solid black;
      height: 40px;
      padding: 0 5vw;
      z-index: 90;
      top: 0;
      background-color: #01e7e5;
      width: 100vw;
      position: absolute;

      text-align: center;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
    }
  }

  @media (max-width: 1000px) {
    .header {
      border-bottom: 1px solid black;
      height: 40px;
      padding: 0;
      z-index: 90;
      top: 0;
      background-color: #01e7e5;
      width: 100vw;
      position: absolute;

      text-align: center;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
    }
  }

  @media (max-width: 850px) {
    .subnav {
      display: none;
    }
    .container {
      position: fixed;
      height: 40px;
      width: 40px;
      border: 1px solid black;
      right: 30px;
      top: 40px;
      padding: -5px 5px 5px 5px;
      display: inline;
      border-radius: 10px;
      box-shadow: -3px 3px 5px black;
    }

    .container-header {
      position: relative;
    }
    .header {
      position: absolute;
      margin: 0;
      z-index: 98;
      width: 100%;
      display: inline;
    }

    /* The subnavigation menu */
    .subnav {
      float: left;
      overflow: hidden;
    }

    /* Subnav button */
    .subnav .subnavbtn {
      font-size: 1rem;
      border: none;
      outline: none;
      color: white;
      padding: 0 1vw;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
      height: 40px;
    }

    /* Add a red background color to navigation links on hover */
    .subnav:hover .subnavbtn {
      color: black;
      border-bottom: 1px solid #fff;
      background-color: darkcyan;
      height: 40px;
    }

    .subnavbtn {
      background: none;
      border: 0em;
      height: 40px;
    }
    .subnavbtn:hover {
      color: black;
    }

    a:hover {
      text-decoration: none;
    }

    .subnav button {
      border-radius: 0;
      padding: 0 1rem;
    }

    #burguer {
      display: none;
      position: fixed;
      top: 0px;
      background-color: #01e7e5;
      text-align: center;
      right: 0;
      border-radius: 20px;
    }

    .mod-subnav {
      background-color: #01e7e5;
      width: 100%;
    }
    .mod-subnavbtn {
      background-color: #01e7e5;
      width: 100%;
      padding: 5px;
      border: 1px solid;
    }
    .mod-subnavbtn1 {
      background-color: #01e7e5;
      width: 100%;
      padding: 5px 25px;
    }

    .mod-subnavbtn:hover {
      color: #fff;
      font-size: 1.2rem;
    }

    .container {
      position: fixed;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #01e7e5;
      z-index: 999;
      top: 15px;
      padding: 5px;
      right: 15px;
    }

    #checkbox-menu {
      position: fixed;
      opacity: 0;
      top: 20px;
      right: 40px;
    }

    label {
      cursor: pointer;
      position: relative;
      height: 40px;
      width: 40px;
      vertical-align: middle;
    }

    label span {
      position: absolute;
      display: block;
      height: 5px;
      width: 30px;
      border-radius: 30px;
      background: #fff;
      transition: 0.25s ease-in-out;
    }

    label span:nth-child(1) {
      top: 12px;
    }

    label span:nth-child(2) {
      top: 22px;
    }

    label span:nth-child(3) {
      top: 32px;
    }
  }
</style>
