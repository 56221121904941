<script>
    import Container from '../components/layout/container.svelte';
    import Accordion from '../components/recursos/accordion.svelte';
    import Image from '../components/recursos/image.svelte';


  </script>

  <style>
    #toggle-1 {
     display:none;
  }

  label {
    -webkit-appearance: push-button;
    -moz-appearance: button;
    display: inline-block;
    cursor: pointer;
    padding: 5px;
  }

  /* CSS padrão da div */
  #mostra {
    display:none;
  }

  /* CSS quando o checkbox está marcado */
  #toggle-1:checked ~ #mostra {
    display:block;
    z-index: 99;
    width: 25vw;
    min-width: 200px;
  }
    input{
      width: 100%;
      height: auto;
    }
    #input-text{
      width: 100%;
      height: auto;
    }
  .contato{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25vw;
    background-color: gray;
    padding: 0 2vw;
    justify-content: center;
  }
  .contato p{
    padding: 2vh 0 0 0;
    margin:  0;
  }
  .contato h4{
    margin-top: 5vh;
  }

  .botao{

    text-align: center;
  }
  button{
    width: auto;
    padding: 0 2vw;
    margin: 5vh auto;
    background-color: #01e7e5;
  }
  h4{
    widows: 100%;
  }
  #input{
    position:fixed;
    right: 0;
    bottom: 0;
    border: 1px solid var(--COR_MODULO);
    width: 25vw;
    min-width: 200px;
    background-color:#01e7e5;
    z-index: 100;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }


  .row{
    justify-items: center;
  }

  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000;
  padding: 0 1rem;
  }


  .col-4 {width: 33.33%;}

  .col-8 {width: 66.66%;}

  </style>




<div  style="margin: 50px 15vw 50px 10vw;">
  <div class="row" style="justify-content: center;">
    <div class="col-4" style="margin-right:0;min-width: 300px;justify-content: center;text-align: center;">
      <img alt='' class="d-none d-md-block" style="width: 100%;" src="assets/FAQ/ilustra 01 faq.png"/>
    </div>
    <div class="col-8" style=" min-width: 300px;padding:0  0 0 50px;">
      <div class="">
        <h2 style="margin: 15px 0;">Perguntas Frequentes</h2>
        <h5 style="margin: 15px 0 50px;">Repondemos as principais perguntas que você pode ter antes de se tornar nosso cliente.</h5>

<Accordion idUnico="accordionSolucoes" style="margin:0;padding:0;width: 100%;">
  <div style="margin: 20px 0 50px 0;">
    <div class="button">
      <h3>A datallog é uma plataforma?</h3>
    </div>
    <div class="content">
      <div>
          Sim. Para projetos com restrições de desenvolvimento interno, disponibilizamos nosso SDK e Plataforma.
        <br><br>
      </div>
    </div>
  </div>
  <div style="margin: 0 0 50px 0;">
    <div class="button">
      <h3>A datallog é uma API de dados?</h3>
    </div>
    <div class="content">
      <div>
        Para a coleta e processamento de algumas fontes (imóveis, licitações, veículos ...) já possuímos APIs prontas pra consumo imediato.
      </div>
    </div>
  </div>
  <div style="margin: 0 0 50px 0;">
    <div class="button">
      <h3>Quanto custa contratar a Datallog?</h3>
    </div>
    <div class="content">
      <div>
        Avaliamos cada projeto individualmente e precificamos baseado em suas particularidades.
      </div>
    </div>
  </div>

  <div style="margin: 0 0 50px 0;">
    <div class="button">
      <h3>Como tenho acesso aos dados coletados?</h3>
    </div>
    <div class="content">
      <div>
        Disponibilizamos uma API completa para integração, assim como os dados nos formatos excel, csv, json ...
      </div>
    </div>
  </div>


  <div style="margin: 0 0 50px 0;">
    <div class="button">
      <h3>É ilegal acessar esses dados?</h3>
    </div>
    <div class="content">
      <div>
        Não. Coletamos dados disponíveis na internet, e de domínio público.
      </div>
    </div>
  </div>


  <div style="margin: 0 0 50px 0;">
    <div class="button">
      <h3>A datallog coleta dados de documentos como PDFs, Planilhas ...?</h3>
    </div>
    <div class="content">
      <div>
       Sim, coletamos e processamos dados "offline" tbm, como PDFs, planilhas e documentos em geral.
      </div>
    </div>
  </div>


</Accordion>
</div></div></div></div>

<div id='bloco' class="row d-none d-md-block" style="margin: 50px 15vw 0px 37vw; width: 52%;">

<div class="col-11" style="width: 100%;min-width: 300px;background-color: #01e7e5;padding: 0 25px;">
  <h2 style=''>Ficou ainda com alguma dúvida?</h2>
</div>
<div class="col-11 d-none d-md-block" style="width: 100%;background-color: #01e7e5; min-width: 300px;padding: 0 25px">


  <div class=""  style="margin:0 50px 30px 50px ;text-align:right;">
    <a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 10px 20px 10px 20px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;

    text-decoration: none;
    ">
<span>Falar com um especialista</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 1px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a>
        </div>




</div>
</div>












<div id='' class="d-block d-md-none" style="width:100%; text-align: center;
vertical-align: middle;  ">

  <div class="" style="width: 100%;background-color: #01e7e5;   ">
    <h2 style='padding-top: 30px;'>Ficou ainda com alguma dúvida?</h2>



    <div class=""  style="margin:50px ;text-align:right;padding-bottom: 30px;">
      <a href="#/contato" style="display: inline-block;
      color: #212529;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #fff;
      color: #000;
      border: 2px solid #000;
      padding: 10px 20px 10px 20px;
      border-radius: 0;
      font-weight: 800;
      margin: 0;
      width: auto;
      position: relative;
      font-size: 1rem;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: .5px;
      -webkit-transition: background .4s,border .4s,color .4s;
      transition: background .4s,border .4s,color .4s;

      text-decoration: none;
      ">
  <span>Falar com um especialista</span>

  <div style="

  display: block;
  position: absolute;
  top: calc(100% + 1px);
  left: calc(0% - 2px);
  width: calc(100% + (2 * 2px));
  border-top: 4px solid #000;
  border-left: 8px solid rgba(0,0,0,0);
  border-right: 8px solid rgba(0,0,0,0);
  height: 0;
  margin:0;
  padding:0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;"></div>
      </a>
          </div>

  </div>
</div>
