<script>
import jQuery from 'jquery';

var user_name;
var user_email;
var user_phone;
var user_message;
var user_challenge;


function Message() {
        user_name = jQuery('#user_name').val().trim();
        user_email = jQuery('#user_email').val().trim();
        user_phone = jQuery('#user_phone').val().trim();
        user_message = jQuery('#input-text').val().trim();
        user_challenge = jQuery('#user_challenge').val().trim();

        var error_input = false;
        removeAllErrosExplanation();

        error_input = (error_input | showInputError(user_name, "#span_user_name", "#user_name"));
        error_input = (error_input | showInputError(user_email, "#span_user_email", "#user_email"));
        error_input = (error_input | showInputError(user_phone, "#span_user_phone", "#user_phone"));
        error_input = (error_input | showInputError(user_message, "#span_input-text", "#input-text"));
        error_input = (error_input | showInputError(user_challenge, "#span_user_challenge", "#user_challenge"));

        if (error_input) {
            //document.body.scrollTop = 0; // For Safari
            //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return;
        }
        if(!validateEmail(user_email)) {
            addErrorExplanation("#span_user_email", "#user_email");
            //document.body.scrollTop = 0; // For Safari
            //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return;
        }

        if(user_phone.length < 11) {
            addErrorExplanation("#span_user_phone", "#user_phone");
            return;
        }

        jQuery('#loading').html('Enviando mensagem <span>.</span> <span>.</span> <span>.</span>');
        sendMessage();
    }


    function showInputError(toCheck, id_span, id_input) {
        if (checkEmpty(toCheck)) {
            addErrorExplanation(id_span, id_input);
            return true;
        } else {
            return false;
        }
    }

    function checkEmpty(str) {
        return (str == null || str == "");
    }

    function addErrorExplanation(id_span, id_input) {
        jQuery(id_input).css('background-color', '#fce4e4');
        jQuery(id_input).css('border', '1px solid #cc0033');
        jQuery(id_span).css('display', 'inline');
        return true;
    }

    function removeAllErrosExplanation() {
        jQuery("#span_user_name").css('display', 'none');
        jQuery("#span_user_email").css('display', 'none');
        jQuery("#span_user_phone").css('display', 'none');
        jQuery("#span_user_challenge").css('display', 'none');
        jQuery("#span_input-text").css('display', 'none');


        jQuery("#user_name").css('background-color', '#fff');
        jQuery("#user_name").css('border', '1px solid #ccc');

        jQuery("#user_email").css('background-color', '#fff');
        jQuery("#user_email").css('border', '1px solid #ccc');
        
        jQuery("#user_phone").css('background-color', 'white');
        jQuery("#user_phone").css('border', '1px solid #ccc');


        jQuery("#user_challenge").css('background-color', 'white');
        jQuery("#user_challenge").css('border', '1px solid #ccc');

        jQuery("#input-text").css('background-color', 'white');
        jQuery("#input-text").css('border', '1px solid #ccc');

        
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function sendMessage() {
        var stringify_info = user_name.concat(user_email).concat('jj')
        var body = {"name": user_name, "email": user_email, "phone": user_phone, "message": user_message, "code": codeM(stringify_info), "cors": "https://mobile.datallog.com"};

        jQuery.ajax({
            async: true,
            type: 'POST',
            url: 'https://53tz4ff69i.execute-api.us-east-1.amazonaws.com/production',
            data: JSON.stringify(body),
            dataType: "json",
            beforeSend: function (data) {
            },
            complete: function (data) {
            },
            success: function (data) {
              if(data['status'] === 0) {
                 jQuery('#main_error').show();
             } else {
                 jQuery('#main_ok').show();
             }
                jQuery('#loading').html('Enviar mensagem');
                jQuery('#user_name').val('')
                jQuery('#user_phone').val('')
                jQuery('#user_challenge').val('')
                jQuery('#user_email').val('')
                jQuery('#input-text').val('')
                setTimeout(function(){ jQuery('#main_error').hide(); jQuery('#main_ok').hide();}, 5000);
            },
            error: function (data) {

                if(data['status'] === 0) {
                    jQuery('#main_error').show();
                } else {
                    jQuery('#main_ok').show();
                }
                jQuery('#loading').html('Enviar mensagem');
                jQuery('#user_name').val('')
                jQuery('#user_phone').val('')
                jQuery('#user_challenge').val('')
                jQuery('#user_email').val('')
                jQuery('#input-text').val('')
                
                setTimeout(function(){ jQuery('#main_error').hide(); jQuery('#main_ok').hide();}, 5000);
            }
        });
    }

    var codeM = function (string) {

        function RotateLeft(lValue, iShiftBits) {
            return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
        }

        function AddUnsigned(lX,lY) {
            var lX4,lY4,lX8,lY8,lResult;
            lX8 = (lX & 0x80000000);
            lY8 = (lY & 0x80000000);
            lX4 = (lX & 0x40000000);
            lY4 = (lY & 0x40000000);
            lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
            if (lX4 & lY4) {
                return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
            }
            if (lX4 | lY4) {
                if (lResult & 0x40000000) {
                    return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                } else {
                    return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                }
            } else {
                return (lResult ^ lX8 ^ lY8);
            }
        }

        function F(x,y,z) { return (x & y) | ((~x) & z); }
        function G(x,y,z) { return (x & z) | (y & (~z)); }
        function H(x,y,z) { return (x ^ y ^ z); }
        function I(x,y,z) { return (y ^ (x | (~z))); }

        function FF(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function GG(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function HH(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function II(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function ConvertToWordArray(string) {
            var lWordCount;
            var lMessageLength = string.length;
            var lNumberOfWords_temp1=lMessageLength + 8;
            var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
            var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
            var lWordArray=Array(lNumberOfWords-1);
            var lBytePosition = 0;
            var lByteCount = 0;
            while ( lByteCount < lMessageLength ) {
                lWordCount = (lByteCount-(lByteCount % 4))/4;
                lBytePosition = (lByteCount % 4)*8;
                lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
                lByteCount++;
            }
            lWordCount = (lByteCount-(lByteCount % 4))/4;
            lBytePosition = (lByteCount % 4)*8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
            lWordArray[lNumberOfWords-2] = lMessageLength<<3;
            lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
            return lWordArray;
        };

        function WordToHex(lValue) {
            var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
            for (lCount = 0;lCount<=3;lCount++) {
                lByte = (lValue>>>(lCount*8)) & 255;
                WordToHexValue_temp = "0" + lByte.toString(16);
                WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
            }
            return WordToHexValue;
        };

        function Utf8Encode(string) {
            string = string.replace(/\r\n/g,"\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {

                var c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        };

        var x=Array();
        var k,AA,BB,CC,DD,a,b,c,d;
        var S11=7, S12=12, S13=17, S14=22;
        var S21=5, S22=9 , S23=14, S24=20;
        var S31=4, S32=11, S33=16, S34=23;
        var S41=6, S42=10, S43=15, S44=21;

        string = Utf8Encode(string);

        x = ConvertToWordArray(string);

        a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

        for (k=0;k<x.length;k+=16) {
            AA=a; BB=b; CC=c; DD=d;
            a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
            d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
            c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
            b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
            a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
            d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
            c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
            b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
            a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
            d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
            c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
            b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
            a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
            d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
            c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
            b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
            a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
            d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
            c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
            b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
            a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
            d=GG(d,a,b,c,x[k+10],S22,0x2441453);
            c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
            b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
            a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
            d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
            c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
            b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
            a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
            d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
            c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
            b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
            a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
            d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
            c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
            b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
            a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
            d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
            c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
            b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
            a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
            d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
            c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
            b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
            a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
            d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
            c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
            b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
            a=II(a,b,c,d,x[k+0], S41,0xF4292244);
            d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
            c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
            b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
            a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
            d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
            c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
            b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
            a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
            d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
            c=II(c,d,a,b,x[k+6], S43,0xA3014314);
            b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
            a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
            d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
            c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
            b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
            a=AddUnsigned(a,AA);
            b=AddUnsigned(b,BB);
            c=AddUnsigned(c,CC);
            d=AddUnsigned(d,DD);
        }

        var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);

        return temp.toLowerCase();
    }


function manyHandlers(element){
    element.addEventListener("click", event => {
        event.preventDefault();
        Message();
        return false;
        
    })
  }

</script>



<style>
  li {
      font-family: var(--FONT_CABIN);
      font-size: 18px;
      list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
      margin-bottom: 0;
    }
    #toggle-1 {
     display:none;
  }

  label {
    -webkit-appearance: push-button;
    -moz-appearance: button;
    display: inline-block;
    cursor: pointer;
    padding: 5px;
  }

  /* CSS padrão da div */
  #mostra {
    display:none;
  }

  /* CSS quando o checkbox está marcado */
  #toggle-1:checked ~ #mostra {
    display:block;
    z-index: 99;
    width: 25vw;
    min-width: 200px;
  }
    input{
      width: 100%;
      height: auto;
    }
    #input-text{
      width: 100%;
      height: auto;
    }
  .contato{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25vw;
    background-color: gray;
    padding: 0 2vw;
    justify-content: center;
  }
  .contato p{
    padding: 2vh 0 0 0;
    margin:  0;
  }
  .contato h4{
    margin-top: 5vh;
  }

  .botao{

    text-align: center;
  }
  button{
    width: auto;
    padding: 0 2vw;
    margin: 5vh auto;
    background-color: #01e7e5;
  }
  h4{
    widows: 100%;
  }
  #input{
    position:fixed;
    right: 0;
    bottom: 0;
    border: 1px solid var(--COR_MODULO);
    width: 25vw;
    min-width: 200px;
    background-color:#01e7e5;
    z-index: 100;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  button{
    padding: 0 1rem;
  }


  .row{
    justify-items: center;
  }

  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000;
  }


  .col-4 {width: 20%;}

  .col-8 {width: 45%;}

  li {
      font-family: var(--FONT_CABIN);
      font-size: 18px;
      list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
    }
    li:not(:last-child) {
     margin-bottom: 10px;
    }


  .modelo{
    margin-right: 15vw; padding-right: 400px;min-height: 70vh;
  }


  #fim{
    display:none;
  }
  #fixo{
    width: 400px;
        justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle;
        position: absolute;
        top: 15vh;
        right: 15vw;
        z-index: 5;
    height:650px;
  }
  #mensagem p{
      margin:  20px 0 0 0;
  }

  @media (max-width:1200px){
  #fixo{
    display:none;
  }
  #fim{
    display:inline;
    width: 70vw;
    margin: 0 15vw;
    padding: 0 15vw;
        justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle;
  }
  .modelo{
    margin-right: 0; padding-right: 0;
  }



  }
  </style>

  <div class='modelo'  id="SDK-Plataforma" style='margin-top:0px; min-height: 0px;'>
    <div class="row" style="justify-content: center">
      <div class="col-4" style="min-width: 300px;justify-content: center;text-align: center;">
        <img alt='' class="d-none d-md-block" style="width: 100%;" src="assets/Contato/ilustra 02 contato.png"/>
      </div>
      <div class="col-8" style=" min-width: 300px;padding: 0 5vw;">
        <div class="">
          <h2>Projetos para coleta de dados ou RPA web</h2>
          <p>Solicite uma solução completa de ponta a ponta para as suas
          necessidades de extração de dados e automação web.</p>
          <div class="d-none d-md-block" >
          <h2 style='padding:0 0 20px 0;margin-top:0'>Porque Datallog?</h2>

          <ul>
              <li style="margin-bottom: 0px;">
                  <h5 style="margin: 0px;">Especialista em extração de dados</h5>
              </li>
              <p style="margin-bottom: 1.2rem;">Da analise inicial à entrega do projeto, você terá acesso a especialistas em coleta de dados e automação web.</p>
              <li style="margin-bottom: 0px;">
                  <h5 style="margin: 0px;">POC</h5>
              </li>
              <p style="margin-bottom: 1.2rem;">Começamos com uma prova de conceito e, uma vez aprovado entregamos a solução completa já integrada aos seus sistemas.</p>
              <li style="margin-bottom: 0px;">
                  <h5 style="margin: 0px;">Confiabilidade</h5>
              </li>
              <p style="margin-bottom: 1.2rem;">Quaisquer que sejam seus desafios, integrações personalizadas, agilidade na adição de novos coletores ...
                  temos uma equipe qualificada pra atender suas necessidades.</p>
              <li style="margin-bottom: 0px;">
                  <h5 style="margin: 0px;">Escalabilidade</h5>
              </li>
              <p style="margin-bottom: 1.2rem;">Nossas soluções se ajustam a medida que sua demanda cresce, de algumas dezenas a milhões
                  de páginas podem ser processadas.</p>
          </ul>
        </div>
  </div></div></div></div>




  <div  class="chat d-block d-md-none" style=' height:auto; '>
    <div class="card-body" style='background-color: #d8d8d8; height: 100%;'>
        <div id="mensagem" class='' style='height: 100%; position: relative;'>
            <h4 style="padding: 0 0 20px 0;text-align: center;margin:0;">Ficou com alguma dúvida?</h4>

            <div id="main_ok" class="alert-success" style="display: none;">
                                    <strong>Mensagem enviada! </strong> em breve entraremos em contato.
                                </div>
                                <div id="main_error" class="alert-danger" style="display: none;">
                                    <strong>Ooh error! </strong> tente enviar mais tarde.
                                </div>


            <p>Seu nome <span id="span_user_name" style="display: none; color: brown;"> (Campo obrigatório)</span></p> 
            <input id="user_name" class='input-box' style="margin-bottom: 15px; height: 33px; padding: 0 0 0 15px;" type="text">
              
            <p>Melhor e-mail <span id="span_user_email" style="display: none; color: brown;"> (Campo obrigatório)</span></p> 
            <input id="user_email" class='input-box' style="margin-bottom: 15px; height: 33px; padding: 0 0 0 15px;" type="email">
            
            <p>Telefone <span id="span_user_phone" style="display: none; color: brown;"> (Campo obrigatório)</span></p> 
            <input id="user_phone" class='input-box sp_celphones' style="margin-bottom: 15px; height: 33px; padding: 0 0 0 15px;" type="tel">
            
            <p>10 + 8 = ? <span id="span_user_challenge" style="display: none; color: brown;"> (Campo obrigatório)</span></p>
            <input id="user_challenge" class='input-box' style="margin-bottom: 15px; height: 33px; padding: 0 0 0 15px;" type="text">
            
            <p>Sua dúvida <span id="span_input-text" style="display: none; color: brown;"> (Campo obrigatório)</span></p> 
            <textarea id="input-text" rows="4" style="width: 100%; height: auto; margin-bottom: 75px;"></textarea>

            <div class='botao' style='width: 100%; position:absolute; bottom:0px; margin-top:50px'><a id="sendMessage" use:manyHandlers href="#/solucoes" style="display: inline-block;
    width: 100%;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #01e7e5;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 10px 75px 10px 75px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;

    ">
<span id="loading">Enviar mensagem</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 1px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a></div>
        </div>
    </div>
  </div>





































