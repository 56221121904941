<script>
</script>


<style>
  * {
    box-sizing: border-box;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row{
    justify-items: center;
  }


  button{
    border:1px solid black;
    box-shadow: 0px 10px 14px -3px #000000;
  padding: 0 1rem;
  }

.icones{
    min-width: 100px;
    width:10vw;
    margin:-20px;
    position: absolute;
    bottom:0;
}


.bloco{
  width:70vw;
  margin:0 15vw;
}

.col{
  min-width: 300px;
}
@media (min-width: 1400px) {
    .col{
      min-width: 20vw;
  }
}
</style>






















 <div id='pag1' class="" style="margin-top: 150px; margin-bottom: 50px; background-color: #f8f9fa !important;">
    <div style=" align-items: center;width: 70vw; margin:0 15vw">
      <div class="row" style="margin: 0;padding:20px;">
        <div class="col" style='min-width: 300px;'>
            <div class="card-body" style='min-width: 300px;'>

            <div style='display:flex;min-height:50px;'>
                <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">1</h5></div>
                <h2 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de informação</h2>
            </div>
            <h3>Monitore preços, produtos,
competidores e defina uma
estratégia de monitoramento.</h3>
            <p>Com a raspagem de dados é possível monitorar milhões de sites em tempo real.
Use essas informações e gere notificações importantes para o seu negócio.</p>
        </div></div>
          <div class="col" style=''>
              <div class="card-body" style='min-width: 300px;height:max-content;text-align:middle;'>
        <img alt='' style='width: 35vw;margin-top: -6vw;margin-bottom: 0vw; margin-left: -2vw;max-width:800px;min-width: 300px;' class="d-none d-md-block" src="assets/Casos de uso/ilustras/ilustra_03_caso.png"/>
    </div></div></div></div></div>

  <div class="">

    <div class="row "style="width:70vw;margin: 0 15vw;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle">
      <div class="col" style='margin:1vw;'>
        <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
          <div class="card-body" style='min-width: 300px;'>
            <div style='display:flex; min-height:50px;;'>
            <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
          <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3.1</h5></div>
              <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de preços</h5>
            </div>
            <p style='padding-bottom: 5vw;padding-top: 10px;'>Rastreie os produtos nas lojas online de seus concorrentes,
              e ajustes automaticamente os preços pra otimizar as vendas.</p>
            <img alt='' src='assets/Casos de uso/icones/icone monitora precos.png' style="" class='icones'/>
  <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
          </div>
        </div>
      </div>
      <div class="col" style='margin:1vw;'>
        <div class="card shadow-sm" style='height: 100%;background-color: #d8d8d8;'>
          <div class="card-body" style='min-width: 300px;'>
            <div style='display:flex; min-height:50px;;'>
            <div style="margin:0; text-justify: middle;background-color: #01e7e5;min-width: 50px;width:50px;height:50px;border-radius: 50%;text-align: center;">
          <h5 style="margin:0;padding: 10px 0;font-size: 1.5rem;">3.2</h5></div>
              <h5 style='padding:0 0 0 10px;vertical-align: middle; margin:0 -10px 0 0;align-self: center;height: auto;'>Monitoramento de citações</h5>
            </div>
            <p style='padding-bottom: 5vw;padding-top: 10px;'>
              Não perca os prazos em nenhuma citação de disputa de
              licitações, ações trabalhistas e cívies.
            </p>
            <img alt='' src='assets/Casos de uso/icones/icone monitora citacoes.png' style="" class='icones'/>
  <!--           <button onclick="window.location.href='#/veja_caso'" style="background-color: black;color:white;margin:0.5vw;min-width: 100px;width:50%;right:0;position: absolute; bottom:0;">veja caso de uso</button>               -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="margin-top: 5vw;">


    <div class="bloco" style=''>
  <div class="card shadow-sm" style='height: 100%;background-color: #01e7e5;margin: 0;'>
    <div class="card-body" style='min-width: 300px;'>
      <h2 style='margin:0;padding: 0vw;'>Interessado em saber como
  a datallog pode ajudar seu negócio?</h2>
    </div>
      <div style="margin:0 50px 30px 50px ;text-align:right;">
    <a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 15px 22px 15px 22px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;

    ">
<span>Conte-nos como podemos te ajudar</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 1px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a>
  </div>    </div>    </div>
</div>























