<script>
  import Container from '../components/layout/container.svelte';
  import Image from '../components/recursos/image.svelte';

import jQuery from 'jquery';



var i = 9;
var txt = 'Coletando';
var speed = 250;
var speed2 = 100;
var text = 'Processando';
var control = 0;
var control_title = 0;
var text_anal = 'Analisando';

function typeWriter() {
    if (control == 0) {
        if (i < txt.length) {
            document.getElementById("business-type").innerHTML += txt.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        } else {
            setTimeout(removeWriter, 2000);
        }
    } else if (control == 1) {
        if (i < text.length) {
            document.getElementById("business-type").innerHTML += text.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        } else {
            setTimeout(removeWriter, 2000);
        }
    } else if (control == 2) {
        if (i < text_anal.length) {
            document.getElementById("business-type").innerHTML += text_anal.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        } else {
            setTimeout(removeWriter, 2000);
        }
    }

}

function removeWriter() {
    if (i > 0) {
        var str = document.getElementById("business-type").innerHTML;
        var newStr = str.substring(0, str.length - 1);
        document.getElementById("business-type").innerHTML = newStr;
        i--;
        setTimeout(removeWriter, speed2);
    } else {
        setTimeout(typeWriter, 200);
        
        if (control == 0) {
          control = 1
        } else if (control == 1) {
          control = 2
        } else if (control == 2) {
          control = 0
        }
    }
}

function changeTitle() {
    if (control_title % 2) {
        document.getElementById("title").innerHTML = '(1) Nova mensagem!';
    } else {
        document.getElementById("title").innerHTML = 'datallog | coletando e processando dados on e offline.';
    }
    control_title++;
    setTimeout(changeTitle, 1500);
}


jQuery(document).ready(function () {
  removeWriter();
  console.log("passou aqui")
  
  changeTitle()
  });





</script>


<style>


    #business-type {
    color: #01e7e5;
    opacity: 0.8;
    background: black;
}

  #bloco{
    left:0;min-width: 300px; margin-left:15vw;margin-right:15vw;justify-content: center;background-color: #01e7e5; margin-top: 100px;
  }
  #como{
    width: 70vw;
    margin: 0 15vw;
  }
  #card{
    justify-items: center;
    min-width: 300px;
    margin:1vw 0;
    max-width: 700px;
    width:25vw;
  }
  @media (max-width: 500px) {
    #card{
      min-width: 100vw;
  }
  #bloco{
    left:0;min-width: 100vw; margin-left:0;margin-right:0;justify-content: center;background-color: #01e7e5; padding: 50px 5px;
  }
  }
  @media (min-width: 1700px) {
    #card{
      min-width: 350px;
  }
  }
  @media (min-width: 1950px) {
    #card{
      min-width: 400px;
  }
  }
  @media (max-width: 1500px) {
    #como{
    width: 100vw;
    margin: 0 ;
  }
  }
@media (max-width: 700px) {
  .col-7t {width: 90vw;}

.trabalhos{
  width: 100%;
  margin:0;
  padding:0;
}
}
* {
  box-sizing: border-box;
}
#pag1{
  background-image: url('https://i.postimg.cc/MpRYzvL0/linha-01.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
#pag2{
  background-image: url('https://i.postimg.cc/Y9zMPhPq/linha-02.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
#pag3{
  background-image: url('https://i.postimg.cc/FKvkCbkJ/linha-03.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
#pag4{
  background-image: url('https://i.postimg.cc/8C6T4XhD/linha-04.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
#pag5{
  background-image: url('https://i.postimg.cc/XvRqnpM0/linha-05.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

}
button{
  padding: 0 1rem;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.caixa{
  background-color: burlywood;
  margin-top:-100px;
  padding: 110px 20px 15px 20px;
  min-height:310px;
  width: 100%;


}
.row{
  justify-items: center;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}
button{
  border:1px solid black;
  box-shadow: 0px 10px 14px -3px #000000;
}


.col-3 {width: 25%;}

.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}

.col-7t {width: 40%;}

.col-12 {width: 100%;}

.trabalhos{
  padding-top: 10px;
  padding-left: 5vw;
  border-bottom: 1px solid #000;

}
.trabalhos-ultimo{
  padding-top: 10px;
  padding-left: 5vw;

}
.trabalhos p{
  margin:0;
}
.trabalhos h4{
  margin:0;
}
.trabalhos-ultimo h4{
  margin:0;
}

li {
    font-family: var(--FONT_CABIN);
    font-size: 18px;
    list-style-image: url("../../../assets/images/recursos/list_bullet.svg");
    margin-bottom: 0;
  }
  li:not(:last-child) {
    margin-bottom: 0;
  }

.logo1{
  width:300px
}
.botaof{
  text-align: right;
}
.fundo{
  align-items: right;
  width: 300px;
}

  @media (max-width: 500px) {
.fundo{
  width:250px;
}

.botaof{
  text-align: center;
}
.logo1{
  width:250px
}
.card-body{
  height: auto;
    margin: 50px 0;
  }
  }
  @media (min-width: 500px) {

  .card-body{
    height: 370px;
  }}
</style>



<div id='pag1' class="d-none d-md-block"  style=" ">
  <div style=" align-items: center;width: 70vw;margin:40px 15vw">
    <div class="row" style="margin: 0;padding:0;">
      <div class="col" style='min-width: 300px;margin-bottom: 50px;'>
            <img  class='logo1' alt='' style='' src="assets/logo datallog/Logo Datallog 04-01.png"/>
            <h2 style='padding: 20px 0; margin:-50px 0 0 0'>Coletando e processando dados para impulsionar negócios</h2>
            <p style='padding:0 '>Oferecemos serviços de coleta de dados e automação
            de processos WEB, assim como ferramentas e know-how
            para que empresas desenvolvam suas aplicações de coleta e processamento de dados on e offline.</p>


            <a href="#/solucoes" class='d-none d-md-block' style="height: 50px;width: 300px;justify-items: right; text-decoration: none;justify-content: right;align-items: right;align-content: right;vertical-align: middle;color:black">
              <img alt=''  style="height: 50px;width: 300px;position: relative;;" src='assets/images/icons/logo.png'><h6 style="color:black; margin-top:-37px;width: 300px;text-align: center;position: absolute;">QUERO CONHECER AS SOLUÇÕES</h6>
            </a>




      </div>
      <div class="col d-none d-md-block" style='min-width: 300px; text-align: right;'>
            <div style='height:100%; vertical-align: middle;text-align: center;'><img alt='' style='width:40vw;padding: 0;min-width: 300px;max-width: 550px;bottom:0;margin-left:-3vw;' src="assets/main page/ilustras/main page 01.png"/> </div>
      </div>
    </div>
    <div id='parceiros' class="row d-none d-md-block" style='justify-content: space-between;margin: 0'>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/gg.jfif'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:100px;margin-top:-25px' src='assets/home/Links/group-software.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/inventti-logo.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/logo.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/logo.webp'/>
              <img alt='' style='filter: grayscale(100%);z-index: 2;height:150px;margin-top:-40px;margin-left:-25px;' src='assets/home/Links/rodobot.jfif'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/wavecode.png'/>
    </div>
  </div>
</div>




<div id='pag1' class="d-block d-md-none" style=" margin-top:50px ">
  <div style=" align-items: center;width: 100vw;margin: 15px">
    <img  class='logo1' alt='' style='' src="assets/logo datallog/Logo Datallog 04-01.png"/>
      <div  style='min-width: 300px;margin-bottom: 50px;'>
            <h2 style='padding: 20px 40px  20px 0; margin:-20px 0 0 0'><b><span id="business-type">Coletando</span></b> dados on/offline <br> para impulsionar negócios</h2>
            <p style='padding:0 40px 0 0  '>Desenvolvemos APIs de dados e automação web sob medida para empresas. E para equipes de desenvolvimento, oferecemos know-how e ferramentas para que desenvolvam suas aplicações.</p>



            <div style=";text-align:center;" class='d-block d-md-none'>
              <a href="#/solucoes" style="display: inline-block;
              color: #212529;
              text-align: center;
              vertical-align: middle;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              background-color: #01e7e5;
              padding: 0.375rem 0.75rem;
              color: #000;
              border: 2px solid #000;
              padding: 10px 20px 10px 20px;
              border-radius: 0;
              font-weight: 800;
              margin: 0;
              width: auto;
              position: relative;
              font-size: 1rem;
              line-height: 1rem;
              text-transform: uppercase;
              letter-spacing: .5px;
              -webkit-transition: background .4s,border .4s,color .4s;
              transition: background .4s,border .4s,color .4s;

              text-decoration: none;
              ">
          <span>QUERO CONHECER AS SOLUÇÕES</span>

          <div style="

          display: block;
          position: absolute;
          top: calc(100% + 1px);
          left: calc(0% - 2px);
          width: calc(100% + (2 * 2px));
          border-top: 4px solid #000;
          border-left: 8px solid rgba(0,0,0,0);
          border-right: 8px solid rgba(0,0,0,0);
          height: 0;
          margin:0;
          padding:0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;"></div>
              </a>
                  </div>


      </div>
      <div class="col d-none d-md-block" style='min-width: 300px; text-align: right;'>
            <div style='height:100%; vertical-align: middle;text-align: center;'><img alt='' style='width:40vw;padding: 0;min-width: 300px;max-width: 550px;bottom:0;margin-left:-3vw;' src="assets/main page/ilustras/main page 01.png"/> </div>
      </div>
    </div>
    <div id='parceiros' class="row d-none d-md-block" style='justify-content: space-between;margin: 0'>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/gg.jfif'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:100px;margin-top:-25px' src='assets/home/Links/group-software.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/inventti-logo.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/logo.png'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/logo.webp'/>
              <img alt='' style='filter: grayscale(100%);z-index: 2;height:150px;margin-top:-40px;margin-left:-25px;' src='assets/home/Links/rodobot.jfif'/>
              <img alt='' style='filter: grayscale(100%);z-index: 3;height:50px;' src='assets/home/Links/wavecode.png'/>
    </div>
  </div>







<div id='pag2' class="album py-5 bg-light" style="">
  <div style=" align-items: center;width: 70vw;margin:0 15vw">
    <div class="row" style="margin: 0;padding:0;align-items: center;justify-content: center;justify-items: center; align-items: center;align-content: center; vertical-align: middle;">
      <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/analise">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 01.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">1</h5></div>
              <h5>Análise de mercado</h5></div>
              <p style='text-align: left;'>Gere insights sobre
                seu mercado a partir de
                dados diponíveis na web</p>

            </div>
          </div>
        </a>
      </div>
  <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/automacao">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 02.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">2</h5></div>
              <h5>Automação WEB</h5></div>
                <p style='text-align: left;'>Automatize os fluxos de trabalho
          repetitivo executados em um
          navegador WEB da sua empresa</p>

            </div>
          </div>
        </a>
      </div>

  <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/monitoramento">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 03.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">3</h5></div>
              <h5>Monitoramento
                de informação</h5></div>
              <p style='text-align: left;'>Monitore preços, produtos,
                competidores e defina uma
                estratégia de notificações</p>

            </div>
          </div>
        </a>
      </div>

  <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/processos">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 04.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">4</h5></div>
              <h5>Leitura
                de documentos</h5></div>
              <p style='text-align: left;'>
                Automatize a consulta e análise
                de documentos das tarefas do dia
                a dia. Automatize a leitura e análise
                de documentos do seu negócio.</p>

            </div>
          </div>
        </a>
      </div>

  <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/avaliacao">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 05.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">5</h5></div>
              <h5>Avaliação
                de fornecedores</h5></div>
              <p style='text-align: left;'>Tenha a avaliação de fornecedores
                em segundos na sua mão</p>

            </div>
          </div>
        </a>
      </div>


  <div id='card' class="col" style=''>
        <a style='text-decoration: none;;' href="#/casos/prospeccao">
          <div class="card shadow-sm card-body" style='width: 100%;;background-color: #d8d8d8;margin:50px 0'>
            <div  style='width: 100%;margin-top:-120px;text-align: center;'>
              <img alt='' style='width: 200px;' src="assets/main page/icones/icones 06.png"/>
              <div style='display:flex;text-align: center;width:100%;justify-content: center;margin:5px 0;'>
                <div style="margin:-3px 10px 0 0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
                  <h5 style="margin:0;padding: 0;font-size: 1.5rem; color: black;">6</h5></div>
              <h5>Prospecção
                de oportunidades</h5></div>
              <p style='text-align: left;'>Monitore a WEB 24/7 em busca
                de leads e oportunidades</p>

            </div>
          </div>
        </a>
      </div>      </div>      </div>
</div>

<div id='bloco' class="row" style="">

  <div class="col-11" style="width: 100%;min-width: 300px;background-color: #01e7e5;padding: 0 25px;">
    <h2 style=''>Seu negócio possui
      alguma demanda específica?</h2>
  </div>
  <div class="col-11" style="width: 100%;background-color: #01e7e5; min-width: 300px;padding: 0 25px">
    <p style='padding: 20px 0 0 0; '>
      Esses são alguns exemplos de como impulsionamos
o crescimento de alguns segmentos. Conte-nos como
podemos te ajudar, e vamos descobrir juntos como
a tecnologia Datallog pode alavancar seus negócios.
    </p>

                <div class="d-none d-md-block"  style="margin:0 50px 30px 50px ;text-align:right;">
    <a href="#/contato" style="display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    color: #000;
    border: 2px solid #000;
    padding: 10px 20px 10px 20px;
    border-radius: 0;
    font-weight: 800;
    margin: 0;
    width: auto;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    -webkit-transition: background .4s,border .4s,color .4s;
    transition: background .4s,border .4s,color .4s;

    text-decoration: none;
    ">
<span>Falar com um especialista</span>

<div style="

display: block;
position: absolute;
top: calc(100% + 1px);
left: calc(0% - 2px);
width: calc(100% + (2 * 2px));
border-top: 4px solid #000;
border-left: 8px solid rgba(0,0,0,0);
border-right: 8px solid rgba(0,0,0,0);
height: 0;
margin:0;
padding:0;
-webkit-box-sizing: border-box;
box-sizing: border-box;"></div>
    </a>
        </div>


      <!--mobile abaixo-->





        <div class="d-block d-md-none" style="text-align:center;">
          <a href="#/contato" style="display: inline-block;
          color: #212529;
          text-align: center;
          vertical-align: middle;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: #fff;
          padding: 0.375rem 0.75rem;
          color: #000;
          border: 2px solid #000;
          padding: 10px 20px 10px 20px;
          border-radius: 0;
          font-weight: 800;
          margin: 0;
          width: auto;
          position: relative;
          font-size: 1rem;
          line-height: 1rem;
          text-transform: uppercase;
          letter-spacing: .5px;
          -webkit-transition: background .4s,border .4s,color .4s;
          transition: background .4s,border .4s,color .4s;

          text-decoration: none;
          ">
      <span>Falar com um especialista</span>

      <div style="

      display: block;
      position: absolute;
      top: calc(100% + 1px);
      left: calc(0% - 2px);
      width: calc(100% + (2 * 2px));
      border-top: 4px solid #000;
      border-left: 8px solid rgba(0,0,0,0);
      border-right: 8px solid rgba(0,0,0,0);
      height: 0;
      margin:0;
      padding:0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;"></div>
          </a>
              </div>

  </div>
</div>










<div id='pag3' style="margin: 5vw 0 2vw 0; width: 100vw;">
  <div id='como' class="row" style="justify-content: center;">
    <div class="" style="min-width: 300px;justify-content: center;text-align: center;width: 30%;">
      <h3 style="margin:0 -50px 0 0;width: 100%;padding-right:-10vw;font-size:3rem;">Como trabalhamos</h3>
      <img alt='' class="d-none d-md-block" style="margin:0 ;width: 100%;" src="assets/main page/ilustras/main page 02.png"/>
    </div>
    <div class="col-7t" style="padding-right: 0; min-width: 300px;width: 100%;max-width: 700px;">
      <div class="trabalhos">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">1</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Análise de demanda</h5>
        </div>
          <ul>
            <li>
              Definição fonte dos dados
            </li>
            <li>
              Escopo do projeto
            </li>
            <li>
              Estrutura dos dados
            </li>
          </ul>
      </div>
      <div class="trabalhos">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">2</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Prova de conceito</h5>
        </div>
        <p>Desenvolvemos uma prova de conceito,
          analisando os desafios como:</p>
          <ul>
            <li>
              Captchas
            </li>
            <li>
              Proxies
            </li>
            <li>
              Controle acesso
            </li>
            <li>
              WAFs
            </li>
          </ul>
      </div>
      <div class="trabalhos">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">3</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Resultados e proposta</h5>
        </div>
          <ul>
            <li>
              Apresentação dos resultados
            </li>
            <li>
              Alinhamento do escopo do projeto
            </li>
            <li>
              Proposta comercial
            </li>
          </ul>
      </div>
      <div class="trabalhos">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">4</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Desenvolvimento</h5>
        </div>
          <ul>
            <li>
              Implementação dos Robôs
            </li>
            <li>
              Testes, deploy
            </li>
            <li>
              Integração
            </li>
          </ul>
      </div>
      <div class="trabalhos">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">5</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Produção e escala</h5>
        </div>
        <ul>
          <li>
            Os robôs são colocados pra rodar e
            ajustados pra atender a demanda do projeto.
          </li>
        </ul>
      </div>
      <div class="trabalhos-ultimo">
        <div style='display:flex;min-height:50px;'>
          <div style="margin:0; text-justify: middle;background-color: #01e7e5;width:30px;height:30px;border-radius: 50%;text-align: center;">
            <h5 style="margin:0;padding: 0;font-size: 1.5rem;">6</h5></div>
          <h5 style='margin:  5px 0 0 10px'>Suporte e manutenção</h5>
        </div>
          <ul>
            <li>
              Possibilidade de suporte 24/7
            </li>
            <li>
              LGPD Compliant
            </li>
            <li>
              SLA em contrato
            </li>
          </ul>
      </div>
    </div>
  </div>
</div>



<div id='pag4'>
  <div id='como' class="row" style="justify-content: center;">
    <div class="" style="min-width: 300px;justify-content: center;text-align: center;width: 30%;">
      <h2 style='margin-top: 20px;padding:0 20px'>Buscando tecnologia para
        sua equipe de desenvolvimento</h2>
      <img alt='' class="d-none d-md-block" style="width: 100%;" src="assets/main page/ilustras/main page 03.png"/>
    </div>
    <div class="col-7t" style="padding-right: 0; min-width: 300px;width: 100%;max-width: 700px;">
      <div style="width: 100%;max-width: 700px;">

        <p style='padding:20px'><b>
          Com nosso SDK e Plataforma ofertamos tecnologia para
            o seu time codar, operar, escalar e manter seus próprios coletores.
        </b></p>
        <div class='' style='width:100%; display:inline-block; padding: 10px 20px;'>
          <img class='col-3' style="min-width: 100px;padding:0;margin:-20px 0 0 0" src="assets/main page/icones/icones 07.png" alt="" />
          <h5>Desenvolvimento</h5>
          <p style='padding:0 20px;margin:0;'> Com o SK datallog codifique, implante e mantenha
            facilmente seus processos de coleta de dados.</p>
        </div>
        <div class='' style='width:100%; display:inline-block; padding: 10px 20px;'>
          <img class='col-3' style="min-width: 100px;padding:0;margin:-20px 0 0 0" src="assets/main page/icones/icones 08.png" alt="" />
          <h5>Operação</h5>
          <p style='padding:0 20px;margin:0;'>Com a plataforma datallog monitore e crie rotinas de execução</p>
        </div>
        <div class='' style='width:100%; display:inline-block; padding: 10px 20px;'>
          <img class='col-3' style="min-width: 100px;padding:0;margin:-20px 0 0 0" src="assets/main page/icones/icones 09.png" alt="" />
          <h5>Escalabilidade</h5>
          <p style='padding:0 20px;margin:0;'> Escale a operação dos robôs sem a preocupação com o
            provisionamento de servidores e <b>pague só pelo o que se usar</b>.</p>
        </div>
        <div class='' style='width:100%; display:inline-block; padding: 10px 20px;'>
          <img class='col-3' style="min-width: 100px;padding:0;margin:-20px 0 0 0" src="assets/main page/icones/icones 10.png" alt="" />
          <h5>Integração</h5>
          <p style='padding:0 20px;margin:0;'> Conecte facilmente seus sistemas aos dados
            coletados com as APIs de integração ou WebHooks.</p>
        </div>


        <a href="#/contato" class='d-none d-md-block' style="height: 50px;width: 300px;justify-items: right; text-decoration: none;justify-content: right;align-items: right;align-content: right;vertical-align: middle;color:black">
          <img alt=''  style="height: 50px;width: 300px;position: relative;;" src='assets/images/icons/logo.png'><h6 style="color:black; margin-top:-37px;width: 300px;text-align: center;position: absolute;">QUERO SABER MAIS</h6>
        </a>



        <div style=";text-align:center;" class='d-block d-md-none'>
          <a href="#/contato" style="display: inline-block;
          color: #212529;
          text-align: center;
          vertical-align: middle;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: #01e7e5;
          padding: 0.375rem 0.75rem;
          color: #000;
          border: 2px solid #000;
          padding: 10px 20px 10px 20px;
          border-radius: 0;
          font-weight: 800;
          margin: 0;
          width: auto;
          position: relative;
          font-size: 1rem;
          text-transform: uppercase;
          letter-spacing: .5px;
          -webkit-transition: background .4s,border .4s,color .4s;
          transition: background .4s,border .4s,color .4s;

          text-decoration: none;
          ">
      <span>QUERO SABER MAIS</span>

      <div style="

      display: block;
      position: absolute;
      top: calc(100% + 1px);
      left: calc(0% - 2px);
      width: calc(100% + (2 * 2px));
      border-top: 4px solid #000;
      border-left: 8px solid rgba(0,0,0,0);
      border-right: 8px solid rgba(0,0,0,0);
      height: 0;
      margin:0;
      padding:0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;"></div>
          </a>
              </div>

      </div>
    </div>
  </div>
</div>





<div id='bloco' class="row" style="margin-top: 5vw">

  <div class="col-11" style="background-color: #01e7e5; min-width: 300px;padding: 50px 25px">
    <h5 style='text-align: left;margin-bottom: 32px;'>
      Ajudamos a sua empresa a automatizar
      ações na WEB e coletar dados estratégicos
      para tomada de decisão e análise de mercado      </h5>



      <div class="d-none d-md-block"  style="margin:0 50px 30px 50px ;text-align:right;">
        <a href="#/contato" style="display: inline-block;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #fff;
        padding: 0.375rem 0.75rem;
        color: #000;
        border: 2px solid #000;
        padding: 10px 20px 10px 20px;
        border-radius: 0;
        font-weight: 800;
        margin: 0;
        width: auto;
        position: relative;
        font-size: 1rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: .5px;
        -webkit-transition: background .4s,border .4s,color .4s;
        transition: background .4s,border .4s,color .4s;

        text-decoration: none;
        ">
    <span>Falar com um especialista</span>

    <div style="

    display: block;
    position: absolute;
    top: calc(100% + 1px);
    left: calc(0% - 2px);
    width: calc(100% + (2 * 2px));
    border-top: 4px solid #000;
    border-left: 8px solid rgba(0,0,0,0);
    border-right: 8px solid rgba(0,0,0,0);
    height: 0;
    margin:0;
    padding:0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;"></div>
        </a>
            </div>


          <!--mobile abaixo-->





            <div class="d-block d-md-none" style="text-align:center;">
              <a href="#/contato" style="display: inline-block;
              color: #212529;
              text-align: center;
              vertical-align: middle;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              background-color: #fff;
              padding: 0.375rem 0.75rem;
              color: #000;
              border: 2px solid #000;
              padding: 10px 20px 10px 20px;
              border-radius: 0;
              font-weight: 800;
              margin: 0;
              width: auto;
              position: relative;
              font-size: 1rem;
              line-height: 1rem;
              text-transform: uppercase;
              letter-spacing: .5px;
              -webkit-transition: background .4s,border .4s,color .4s;
              transition: background .4s,border .4s,color .4s;

              text-decoration: none;
              ">
          <span>Falar com um especialista</span>

          <div style="

          display: block;
          position: absolute;
          top: calc(100% + 1px);
          left: calc(0% - 2px);
          width: calc(100% + (2 * 2px));
          border-top: 4px solid #000;
          border-left: 8px solid rgba(0,0,0,0);
          border-right: 8px solid rgba(0,0,0,0);
          height: 0;
          margin:0;
          padding:0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;"></div>
              </a>
                  </div>
  </div>
</div>
